/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 16:06:10 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-07 15:54:05
 */
<template>
  <div>
    <el-row type="flex" align="middle" v-for="(li,i) in list" :key="i">
      <el-col :span="12">
        <p class="title">
          {{li.PaperName}}
          <span class="c_1288F4">【{{li.AllQuestionCount}}道题】</span>
        </p>
        <p class="time">练习时间：{{li.QustionTime}}</p>
      </el-col>
      <el-col :span="4">
        <p class="ddtm">答对：{{li.CorrectQuestionCount==null?0:li.CorrectQuestionCount}}道题</p>
      </el-col>
      <el-col :span="8">
        <p class="jxzt">
          <!-- <span class="c_1288F4">查看解析</span> -->
          <el-button @click="make(li)">重新做题</el-button>
          <el-button @click="seeDetails(li)">查看详情</el-button>
        </p>
      </el-col>
    </el-row>
    <div v-if="list.length<=0">
      <no-data></no-data>
    </div>
    <el-pagination
      v-if="list.length>0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import { questionBankQueryUserPaperRecord } from "@/api/personal";
import { studentCompareFace } from "@/api/api";
import camera from "@/components/canvasCamera.vue";
import noData from "@/components/noData";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      dialogVisible:false,
      jump:{}
    };
  },
  components: {
    camera,noData
  },
  created() {
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: "init",
  },
  methods: {
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.$router.push({ path: this.jump.url, query: this.jump.ob });
      } else {
        this.$message.error("识别失败");
        this.dialogVisible = false;
      }
    },
    //重新做题
    make(obj){
      if(this.$route.query.CourseActive == "false")
      {
        this.$message.error("对不起，当前课程不在有效期内，不能练习");
        return false;
      }
      let ob = {
        Id:obj.PaperId,
        Name:obj.PaperName,
        UserID:this.userInfo.Id,
      }
      this.jump.url = "/onlineQuestionBank/answer";
      this.jump.ob = ob;
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // return false
      // if (obj.Category == 2) {
      //   this.$router.push({ path: this.jump.url, query: this.jump.ob });
      // }else{
      //   this.dialogVisible = true;
      // }
    },
    seeDetails(obj){
      if(this.$route.query.CourseActive == "false")
      {
        this.$message.error("对不起，当前课程不在有效期内，不能查看");
        return false;
      }
      let ob = {
        DataId:obj.DataId
      }
      this.jump.ob = ob;
      this.jump.url = "/onlineQuestionBank/examRecordDetails";
      this.$router.push({ path: this.jump.url, query: this.jump.ob });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.init();
    },
    async init() {
      let studentId = this.userInfo.Id
      this.route = this.$route.query;
      let data = "?courseId="+this.route.CourseId+
      "&studentId="+studentId+
      "&pageIndex="+this.page+
      "&pageSize="+this.pageSize
      const res = await questionBankQueryUserPaperRecord(data);
      if (res.success === true) {
        this.list = res.response.data;
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-row {
  border-bottom: 1px solid #f0eeee;
  padding: 20px 0;
  .el-col {
    text-align: center;
    &:first-child {
      text-align: left;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 100%;
    }
    .time {
      margin-top: 25px;
      line-height: 100%;
      color: #999999;
    }
    .jxzt {
      .el-button {
        margin-left: 40px;
        width: 88px;
        line-height: 34px;
        padding: 0;
        height: 34px;
        color: #ffffff;
        background-color: #1288f4;
        border-radius: 4px;
      }
    }
  }
}
</style>